import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [2];

export const dictionary = {
		"/[[lang]]/_dev": [~6,[2],[3]],
		"/[[lang]]/about": [7,[2],[3]],
		"/[[lang]]/contact": [8,[2],[3]],
		"/[[lang]]/legal/imprint": [9,[2,4],[3]],
		"/[[lang]]/legal/privacy": [10,[2,4],[3]],
		"/[[lang]]/legal/terms": [11,[2,4],[3]],
		"/[[lang]]/locations": [12,[2],[3]],
		"/[[lang]]/products": [13,[2],[3]],
		"/[[lang]]/products/beta/[product]": [~14,[2],[3]],
		"/[[lang]]/products/bricks": [~15,[2],[3]],
		"/[[lang]]": [5,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';